.App {
  text-align: center;
}

a{
  text-decoration: none;
  color: #1677ff;
}

.error-img{
  height: 200px;
  margin-top: 20px;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
}

.gsi-material-button:disabled .gsi-material-button-state {
  background-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #001d35;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #001d35;
  opacity: 8%;
}

@media screen and (min-width: 768px){

  .dashboard-table{
    margin:auto;
    max-width:65%;
  }

  .disclaimer-title{
    padding:10px;
    font-size:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
  }

  .footer{
    position: absolute;
    bottom: 20px;
    width: 100%;
    margin-left: auto;
  }

  .icon-delete{
    cursor:pointer;
    color:red;
    margin-left:10px;
  }

  .icon-delete:hover{
    color:rgb(249, 96, 96);
  }

  .icon-edit{
    cursor:pointer;
    color:blue;
    margin-left:10px;
  }

  .icon-edit:hover{
    color:rgb(94, 94, 252);
  }

  .icon-run{
    cursor:pointer;
    color:green;
  }

  .icon-run:hover{
    cursor:pointer;
    color:rgb(6, 202, 6);
  }

  .indicator-section{
    border-bottom: 0.5px solid lightgray;
    margin-bottom: 2%;
    padding: 1%;
  }

  .loader{
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }

  .landing-desc{
    justify-content:left;
    width:90%;
    margin:auto;
    margin-bottom:50px;
  }

  .landing-title{
    padding:10px;
    margin-left: auto;
    font-size:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid lightgray;
  }

  .landing-container{
    margin-top: 10%;
    border: 1px solid lightgray;
    width:80%;
    padding:40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    display:flex;
  }

  .navbar-container{
    width: 100%;
    height: 30%;
    display: flex;
    border-bottom:1px solid lightgray;
    box-shadow: inset;
  }

  .navbar-title{
    padding:10px;
    margin-left: 2%;
    font-size:25px;
    display: flex;
    align-items: flex-end;
  }

  .navbar-user{
    padding:10px;
    font-size:15px;
    margin-top:auto;
    margin-bottom:auto;
    margin-right: 10px;
  }

  .stockDataButton{
    width:100px;
    cursor: pointer;
    padding:5px;
  }

  .stockContainer{
    margin-top: 50px;
    padding:10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  th, td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  .placeholder{
    font-size:40px;
    color: lightgray;
    margin-top: 10%;
  }

  .remove-parameter{
    position:absolute;
    left:83%;
  }

  .strategy-metrics{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20%;
  }

  .strategy-metrics-col{
    position: absolute;
    left: 20%;
    text-align: left;
    font-size: small;
  }

  .strategy-metric-element{
    align-items: center;
    padding: 10px;
  }

  .strategy-metric-desc{
    align-items: center;
    padding: 10px;
    font-size: x-large;
    font-weight: bold;
  }

  .strategy-back{
    position:absolute; 
    left:5%;
    margin-top:10px;
  }

  .strategy-button{
    margin-left: 50%;
  }
}

@media screen and (max-width: 768px){

  .dashboard-table{
    margin:auto;
    max-width:90%;
  }

  .disclaimer-title{
    margin-top: 30%;
    padding:10px;
    font-size:30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer{
    position: absolute;
    bottom: 10px;
    width: 100%;
    margin-left: auto;
  }

  .icon-delete{
    cursor:pointer;
    color:red;
    margin-left:10px;
  }

  .icon-delete:hover{
    color:rgb(249, 96, 96);
  }

  .icon-edit{
    cursor:pointer;
    color:blue;
    margin-left:10px;
  }

  .icon-edit:hover{
    color:rgb(94, 94, 252);
  }

  .icon-run{
    cursor:pointer;
    color:green;
  }

  .icon-run:hover{
    cursor:pointer;
    color:rgb(6, 202, 6);
  }

  .indicator-section{
    border-bottom: 0.5px solid lightgray;
    margin-bottom: 3%;
    padding: 5%;
    padding-left:0;
  }

  .loader{
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }

  .landing-desc{
    justify-content:center;
    width:100%;
    margin:auto;
    margin-bottom:50px;
    padding-top: 10%;
  }

  .landing-title{
    padding:10px;
    font-size:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid lightgray;
  }

  .landing-container{
    margin-top: 10%;
    border: 1px solid lightgray;
    width:70%;
    padding:40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
  }

  .navbar-container{
    width: 100%;
    height: 30%;
    display: flex;
    border-bottom:1px solid lightgray;
    box-shadow: inset;
  }

  .navbar-title{
    padding:10px;
    margin-left: 2%;
    font-size:25px;
    display: flex;
    align-items: flex-end;
  }

  .navbar-user{
    display: none;
  }

  .stockDataButton{
    width:100px;
    cursor: pointer;
    padding:5px;
  }

  .stockContainer{
    padding:0px;
  }

  .strategy-back{
    position:absolute; 
    left:2%;
    top:12%;
  }

  .placeholder{
    margin-top: 20%;
    font-size:25px;
    color: lightgray;
  }

  .remove-parameter{
    position:absolute;
    left:93%;
    font-size: 20px;
    color: gray;
  }

  .strategy-metrics{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;  
    margin: auto;
    padding-top: 2%;
  }

  .strategy-metrics-col{
    text-align: center;
    font-size: small;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 0;
    width: 80%;
    border-bottom: 1px solid lightgray;
  }

  .strategy-metric-element{
    align-items: center;
    font-size: small;
    padding: 10px;
  }

  .strategy-metric-desc{
    align-items: center;
    padding: 10px;
    font-size: large;
    font-weight: bold;
  }

  .strategy-button{
    margin-left: 0%;
    margin-top: 5%;
    width: 80%;
    padding-bottom: 5%;
    border-bottom: 1px solid lightgray;
  }
}


